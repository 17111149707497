import classNames from 'classnames';
import { EntryFields } from 'contentful';

import { Usp } from '@features/pg-funnel/hooks/use-funnel-config/types';
import { Image as IImage } from '@features/pg-funnel/services/contentful/types';
import { Button } from '@features/shared/components/button';
import { Image } from '@features/shared/components/image';
import { Layout } from '@features/shared/components/layout';
import Icons from 'features/shared/components/icons';
import { RichText } from 'src/features/shared/components/rich-text';
import { Link } from 'features/shared/components/link';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { ImageCollage } from 'src/features/shared/components/page-header/image-collage';
import { FontColor, H4 } from 'src/features/shared/components/typography';
import { UspItem } from 'src/features/shared/components/usp-item';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import useLang from 'utils/hooks/use-lang';
import { ILink } from '../../contentful/types/ILink';
import { PageTitle } from '../typography/page-title/PageTitle';
import styles from './index.module.scss';
import { IPrevLink } from './types/IPrevLink';



export interface IPageHeaderProps {
  hasFontColorLight?: boolean;
  title: string;
  subtitle?: string;
  description?: EntryFields.RichText;
  cta?: ILink;
  image: IImage;
  mobileImage?: IImage;
  mainImageSizes?: string;
  collageImages?: IImage[];
  contentAlignment?: string;
  isCollageStyle?: boolean;
  prevLink?: IPrevLink;
  children?: React.ReactNode;
  usps?: Usp[];
}

export const PageHeader = ({
  hasFontColorLight,
  title,
  subtitle,
  description,
  cta,
  image,
  mobileImage,
  collageImages,
  contentAlignment = 'Left',
  isCollageStyle = false,
  prevLink,
  children,
  usps,
  mainImageSizes,
}: IPageHeaderProps) => {
  const { lang } = useLang();
  const { renderRichText } = useRichTextRenderer(lang);
  const { breakpoints, currentBreakpoint } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;
  const descriptionToRender = description && renderRichText(description);
  const isLightTheme =
    !isCollageStyle &&
    ((!image && !mobileImage) || hasFontColorLight !== false);
  const isAlignedCenter = contentAlignment === 'Center' && !isCollageStyle;

  const columnProps = isCollageStyle
    ? { columns: 'sm:5', offset: 'sm:1' }
    : {
        columns: 'xs:12',
      };


    
  return (
    <div
      className={classNames(styles.container, {
        [styles.alternative]: !image,
        [styles['is-neutral-06']]: !isLightTheme,
        [styles.isCollage]: isCollageStyle,
      })}
    >
      {image && !isCollageStyle ? (
        <div
          className={
            title &&
            classNames(styles['image-container'], {
              [styles.transparent]: !isLightTheme,
              [styles.centralized]: isAlignedCenter,
            })
          }
        >
          <Image
            className={styles.image}
            image={isMobile && mobileImage ? mobileImage : image}
            hasLazyLoading={false}
            sizes={mainImageSizes}
          />
        </div>
      ) : null}
      <Layout.Container>
        <Layout.Row className={styles.row}>
          <Layout.Col {...columnProps}>
            <div
              className={classNames(styles.body, {
                [styles.centralized]: isAlignedCenter,
                [styles.bodyPaddingTop]: !isCollageStyle,
              })}
            >
              {subtitle && (
                <div className={styles.subtitle}>
                  <H4
                    color={
                      isLightTheme ? FontColor.Neutral06 : FontColor.Neutral01
                    }
                  >
                    {subtitle}
                  </H4>
                </div>
              )}
              {title && (
                <PageTitle
                  title={title}
                  isLight={isLightTheme}
                  className={styles.title}
                />
              )}
              {descriptionToRender && (
                <RichText
                  isLight={isLightTheme}
                  className={styles.largeSizeOnTablet}
                >
                  <>{descriptionToRender}</>
                </RichText>
              )}
              {usps && (
                <ul className={styles.usps}>
                  {usps.map((usp, index) => (
                    <UspItem
                      key={index}
                      usp={usp}
                      index={index}
                      isColorNeutral06={isLightTheme}
                    />
                  ))}
                </ul>
              )}
              {cta && (
                <div className={styles.cta}>
                  <Button href={cta.href} openInNewTab={cta.openInNewTab}>
                    {cta.label}
                  </Button>
                </div>
              )}
              <Layout.Row>
                <Layout.Col columns={isAlignedCenter ? 'sm:12' : 'sm:8'}>
                  {children}
                </Layout.Col>
              </Layout.Row>
              {prevLink && (
                <div className={styles.link}>
                  <Link
                    href={prevLink?.href}
                    icon={Icons.ArrowLeft}
                    className={styles.prevLink}
                  >
                    {prevLink?.label}
                  </Link>
                </div>
              )}
            </div>
          </Layout.Col>
          {isCollageStyle && collageImages ? (
            <Layout.Col
              className={styles.collageCol}
              columns="sm:6 md:5 lg:4"
              offset="md:1"
            >
              <ImageCollage images={collageImages} />
            </Layout.Col>
          ) : null}
        </Layout.Row>
      </Layout.Container>
    </div>
  );
};
