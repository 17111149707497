import { useState } from 'react';
import { Image } from 'src/features/pg-funnel/services/contentful/types';
import styles from './ClubCardHeaderImage.module.scss';
import Slider from 'react-slick';
import {
  ArrowButton,
  ButtonPosition,
} from 'src/features/shared/components/arrow-button';
import { SlickDots } from 'src/features/shared/components/slick-dots';
import { ContentfulImage } from 'src/features/shared/components/contentful-image';
import { useIntl } from 'src/domains/i18n';

const SliderArrowButton = ({
  onClick,
  isPrev,
}: React.ComponentProps<typeof ArrowButton>) => {
  const { formatMessage } = useIntl();
  return (
    <ArrowButton
      place={ButtonPosition.Centered}
      isPrev={isPrev}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
      aria-label={formatMessage(
        `carousel.button.${isPrev ? 'previous' : `next`}`,
      )}
    />
  );
};

interface IClubCardHeaderProps {
  images?: Image[];
}

export function ClubCardHeaderImage({ images }: IClubCardHeaderProps) {
  const [slides, setSlides] = useState<{
    currentSlide: number;
    nextSlide: number | null;
  }>({ currentSlide: 0, nextSlide: null });

  const beforeSlideChange = (currentSlide: number, nextSlide: number) => {
    setSlides({
      currentSlide,
      nextSlide,
    });
  };

  const renderImage = (image: Image) => {
    return (
      <ContentfulImage
        /**
         * todo: remove this ts-ignore when the image type is updated
         * ticket: https://olympusinvestment.atlassian.net/jira/software/c/projects/SC/boards/28?quickFilter=135&selectedIssue=SC-3958
         */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        src={image.srcOriginal}
        alt={image.alt}
        sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 25vw"
        loading="lazy"
        className={styles.image}
        fill
      />
    );
  };

  if (!images?.length) {
    return <div className={styles.container} />;
  }

  if (images.length === 1) {
    return <div className={styles.container}>{renderImage(images[0])}</div>;
  }

  return (
    <div className={styles.container} role="region">
      <Slider
        dots={true}
        className={styles.imageCarousel}
        prevArrow={<SliderArrowButton isPrev />}
        nextArrow={<SliderArrowButton />}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        appendDots={(dots: any) => <SlickDots dots={dots} {...slides} />}
        beforeChange={beforeSlideChange}
      >
        {images.map((image) => (
          <div className={styles.slide} key={image.src}>
            {renderImage(image)}
          </div>
        ))}
      </Slider>
    </div>
  );
}
