import ConfettiEffect from '@features/pg-funnel/components/confetti';
import { PageHeader } from '@features/shared/components/page-header/PageHeader';
import ContentBlocksSectionView from '@views/sections/content-blocks';
import { useIntl } from 'src/domains/i18n';
import { CONTENTFUL_TAGS } from 'src/features/pg-funnel/utils/constants';
import { ContentIndex } from 'src/features/shared/components/content-index';
import { MetaHead } from 'src/features/shared/components/meta-head';
import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';
import ClubSearchBarSectionView from 'views/sections/club-search-bar';

interface IContentPageViewProps {
  page: IContentPage;
}

export const ContentPageView = ({ page }: IContentPageViewProps) => {
  const { formatMessage } = useIntl();

  const isCollageStyle = page.pageHeader?.imageStyle === 'Collage';
  // Only pages with tag SEO should display the content index component
  const showContentIndex = page.contentfulTags.includes(CONTENTFUL_TAGS.seo);

  return (
    <>
      {page.showConfetti && <ConfettiEffect />}
      <MetaHead
        title={page.metaTitle}
        description={page.metaDescription}
        noIndex={page.noIndex}
      />

      {page.pageHeader && (
        <PageHeader
          key={page.id}
          image={page.pageHeader?.image}
          isCollageStyle={isCollageStyle}
          collageImages={page.pageHeader?.collageImages}
          hasFontColorLight={page.pageHeader.hasFontColorLight}
          mobileImage={page.pageHeader?.mobileImage}
          title={page.pageHeader.title}
          subtitle={page.pageHeader.subtitle}
          description={page.pageHeader.description}
          cta={page.pageHeader.cta}
          usps={page.pageHeader.usps}
          contentAlignment={page.pageHeader?.contentAlignment}
          mainImageSizes={page.pageHeader.mainImageSizes}
        >
          {page.pageHeader?.hasClubSearch && (
            <ClubSearchBarSectionView
              redirect
              inputPlaceholder={formatMessage(
                'content-page.club-search.placeholder',
              )}
              hasFontColorLight={
                !isCollageStyle && page.pageHeader.hasFontColorLight
              }
            />
          )}
        </PageHeader>
      )}

      {showContentIndex && (
        <ContentIndex
          title={page.contentTableTitle}
          contentBlocks={page.contentBlocks}
        />
      )}
      {page.contentBlocks && (
        <ContentBlocksSectionView
          page={page}
          contentBlocks={page.contentBlocks}
        />
      )}
    </>
  );
};
